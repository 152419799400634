.home .entry {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  opacity: 1;
  transition: all 0.7s ease-in-out;
  -webki-transition: all 0.7s ease-in-out;
  z-index: 999;
  pointer-events: none;
}

.home .entry.hide {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  z-index: 0;
}

.home .entry .logo {
  position: fixed;
  margin-top: -20rem;
  z-index: 9999;
  transform: scale(2);
  opacity: 0;
  transition: 2.25s ease-in-out;
}

.home .entry .logo.show {
  margin-top: -15rem;
  opacity: 1;
}

.home .entry-video {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 999;
}

@media only screen and (max-width: 768px) {
  .home .entry .logo {
    transform: scale(1);
    margin-top: -8rem;
  }

  .home .entry .logo.show {
    margin-top: -5rem;
  }
}
