.aside-menu {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 100px;
  height: 100%;
  background-color: transparent;
  border-right: 1px solid var(--primary-text-color);
  color: var(--primary-text-color);
  padding-top: 7rem;
  z-index: 9;
  overflow-y: clip;
}

.aside-menu h1 {
  transform: rotate(-90deg);
  font-family: var(--style-font);
  margin: 0;
  white-space: nowrap;
  font-weight: lighter;
  opacity: 0.7;
}

.aside-menu a {
  color: var(--primary-text-color);
}

.aside-menu .menu-line {
  height: 100px;
  width: 20px;
  cursor: pointer;
  margin-left: -0.8rem;
  display: flex;
}

.aside-menu .menu-line span {
  height: 40px;
  width: 20px;
  border-right: 1px solid var(--primary-text-color);
}

.aside-menu .socila-media-container {
  margin-top: -5rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.aside-menu .menu-drawer {
  position: fixed;
  top: 0;
  left: 101px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease;
  padding-left: 4rem;
  backdrop-filter: blur(10px);
}

.aside-menu .menu-drawer img {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.aside-menu .menu-drawer ul {
  list-style: none;
}

.aside-menu .menu-drawer ul .navigation-cells {
  margin-bottom: 2rem;
}

.aside-menu .menu-drawer ul .navigation-cells li {
  font-family: var(--heading-font);
  font-size: 4em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.aside-menu .menu-drawer ul .navigation-cells li:hover {
  color: var(--secondary-text-color);
}

.aside-menu .menu-drawer ul .navigation-cells a:hover {
  color: var(--secondary-text-color);
}

.aside-menu .menu-drawer ul .navigation-cells li a {
  color: var(--primary-text-color);
  transition: all 0.3s ease;
}

.aside-menu .menu-drawer ul .navigation-cells .dropdown li {
  font-size: large;
  margin-left: 1rem;
}

.aside-menu .menu-drawer.show {
  opacity: 1;
  pointer-events: all;
}

@media only screen and (max-width: 768px) {
  .aside-menu {
    max-width: 50px;
    justify-content: space-evenly;
    height: 100vh;
    padding-top: 2rem;
  }

  .aside-menu h1 {
    font-size: 1.1em;
  }

  .aside-menu .menu-line {
    margin-left: -0.5rem;
    margin-top: 4rem;
    margin-bottom: 4.5rem;
  }

  .aside-menu .socila-media-container a {
    display: flex;
    justify-content: center;
  }

  .aside-menu .socila-media-container img {
    width: 70%;
  }

  .aside-menu .menu-drawer {
    top: 0;
    left: 3.5rem;
    padding-left: 2rem;
    height: 100vh;
  }

  .aside-menu .menu-drawer img {
    max-width: 100px;
  }

  .aside-menu .menu-drawer ul .navigation-cells li {
    font-size: 2em;
  }
}
