.footer {
  position: relative;
  z-index: 1;
  margin-left: 6.3rem;
  padding-bottom: 2rem;
  color: var(--primary-text-color);
  background-color: #111;
  border-top: 1px solid #fff;
}

.footer a {
  text-decoration: none;
  color: var(--primary-text-color);
}

.footer h1 {
  font-family: var(--style-font);
  font-weight: lighter;
  opacity: 1;
  margin-bottom: 0.5rem;
}

.footer ul li {
  margin-bottom: 0.25rem;
}

.footer ul li,
td {
  font-family: var(--body-font);
  opacity: 0.6;
}

.footer table {
  border-collapse: collapse;
  width: 100%;
}

.footer th,
td {
  text-align: left;
  min-width: 200px;
}

.footer table tr {
  display: flex;
  gap: 50px;
}

.footer .top-footer {
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
}

.footer .bottom-footer {
  padding-left: 2rem;
}

.footer .bottom-footer ul {
  list-style: none;
}

.footer .bottom-footer ul li {
  opacity: 1;
  font-size: small;
}

.footer .top-footer .footer-information {
  display: flex;
  justify-content: space-between;
  max-width: 850px;
  width: 100%;
  padding-left: 1rem;
}

.footer .contact-block ul {
  padding-left: 0;
  list-style: none;
  max-width: 190px;
}

.footer .logo-container {
  margin-top: 2rem;
  margin-right: 2rem;
}

.footer .logo-container img {
  max-width: 250px;
}

@media only screen and (max-width: 768px) {
  .footer {
    margin-left: 3.1rem;
  }

  .footer .top-footer {
    padding-left: 0;
  }

  .footer .top-footer .logo-container {
    display: none;
  }

  .footer .top-footer .footer-information {
    flex-direction: column;
    padding-left: 0;
  }

  .footer .top-footer .footer-information div {
    text-align: center;
  }

  .footer .top-footer .footer-information div ul {
    margin: 0 auto;
  }

  .footer .top-footer .footer-information div ul li {
    font-size: small;
  }

  .footer table {
    max-width: 250px;
    margin: 0 auto;
  }

  .footer table tr {
    flex-direction: column;
    gap: 1px;
    margin-bottom: 1rem;
  }

  .footer table tr td {
    text-align: center;
  }

  .footer .bottom-footer {
    text-align: center;
    padding-left: 0;
    margin-top: 3rem;
  }
}
