.canvas {
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw;
  z-index: 999;
  background-color: #000;
  filter: blur(0px);
  transform: scale(1.05);
  transition: 3s ease-in-out;
}
