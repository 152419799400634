.showcase {
  position: relative;
  z-index: 1;
  padding-left: 6.3rem;
  display: flex;
}

.showcase .slider {
  width: fit-content;
  height: auto;
  border-right: 1px solid #fff;
}

.showcase .slider img {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 50vw;
  max-height: 900px;
  object-fit: cover;
}

.showcase .libations {
  background-color: #111;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.showcase .libations .header {
  color: var(--primary-text-color);
  max-width: 450px;
  margin: 0 auto;
}

.showcase .libations .header h1 {
  font-family: var(--style-font);
  font-size: 3em;
  font-weight: lighter;
  margin-bottom: 0;
}

.showcase .libations .header p {
  font-family: var(--heading-font);
  font-size: 1.3em;
  opacity: 0.7;
}

.showcase .libations .still-life {
  max-width: 450px;
  margin: 0 auto;
  color: var(--primary-text-color);
}

.showcase .libations .title-container h1:nth-child(1) {
  margin-bottom: -1rem;
}

.showcase .libations .title-container h1:nth-child(2) {
  opacity: 0.3;
  margin: 0;
  transform: scaleY(-1);
  filter: blur(2px);
}

.showcase .libations .still-life h1 {
  font-family: var(--style-font);
  font-weight: lighter;
  font-size: 2.5em;
}

.showcase .libations .still-life h2 {
  font-family: var(--heading-font);
  font-weight: lighter;
  margin: 0;
}

.showcase .libations .still-life p {
  font-family: var(--body-font);
  opacity: 0.6;
  margin-top: 0;
}

.showcase .libations .imagined-life {
  max-width: 450px;
  margin: 0 auto;
  color: var(--primary-text-color);
  text-align: right;
}

.showcase .libations .title-container h1:nth-child(1) {
  margin-bottom: -1rem;
}

.showcase .libations .imagined-life .title-container h1:nth-child(2) {
  opacity: 0.7;
  margin: 0;
  transform: scaleY(-1);
  filter: blur(2px);
  color: var(--secondary-text-color);
}

.showcase .libations .imagined-life h1 {
  font-family: var(--style-font);
  font-weight: lighter;
  font-size: 2.5em;
}

.showcase .libations .imagined-life h2 {
  font-family: var(--heading-font);
  font-weight: lighter;
  margin: 0;
}

.showcase .libations .imagined-life p {
  font-family: var(--body-font);
  opacity: 0.6;
  margin-top: 0;
}

.showcase .libations .menu-links {
  display: flex;
  justify-content: space-evenly;
  width: 65%;
  margin-top: 4rem;
  margin-bottom: 3rem;
  align-items: center;
}

.showcase .libations .menu-links li {
  font-family: var(--body-font);
  color: var(--primary-text-color);
  list-style: none;
  font-size: 1.2em;
}

.showcase .libations .menu-links li a {
  color: var(--primary-text-color);
  padding-bottom: 0.25rem;
  border-bottom: 1px solid var(--primary-text-color);
  transition: all 0.2s ease;
}

.showcase .libations .menu-links li a:hover {
  color: var(--secondary-text-color);
}

.showcase .libations .menu-links span {
  color: var(--primary-text-color);
  font-size: xx-large;
}

.showcase .libations .menu-links span:nth-child(4) {
  display: none;
}

@media only screen and (max-width: 768px) {
  .showcase {
    flex-direction: column;
    padding-left: 3.5rem;
  }

  .showcase .slider {
    border-right: none;
  }

  .showcase .libations {
    padding-bottom: 3rem;
  }

  .showcase .libations .header {
    text-align: center;
  }

  .showcase .libations .header p {
    font-size: 0.9em;
    max-width: 300px;
  }

  .showcase .libations .still-life {
    text-align: center;
    max-width: 270px;
  }

  .showcase .libations .still-life .title-container h1 {
    font-size: 2em;
  }

  .showcase .libations .still-life h2 {
    font-size: 1em;
    text-align: center;
    margin-bottom: 1rem;
    display: none;
  }

  .showcase .libations .still-life p {
    font-size: 0.8em;
  }

  .showcase .libations .imagined-life {
    text-align: center;
    max-width: 270px;
  }

  .showcase .libations .imagined-life h2 {
    font-size: 1em;
    text-align: center;
    margin-bottom: 1rem;
    display: none;
  }

  .showcase .libations .imagined-life p {
    font-size: 0.8em;
  }

  .showcase .libations .imagined-life .title-container h1 {
    white-space: nowrap;
    font-size: 2em;
  }

  .showcase .libations .menu-links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .showcase .libations .menu-links li {
    font-size: 1em;
  }

  .showcase .libations .menu-links span {
    display: none;
  }
}
