@font-face {
  font-family: "Felixti";
  src: url(./Assets/Felixti.TTF);
}

@font-face {
  font-family: "Orator";
  src: url(./Assets/OratorStd.otf);
}

@font-face {
  font-family: "Roboto Mono";
  src: url(./Assets/RobotoMono-Regular.ttf);
}

:root {
  --primary-text-color: #f9f6e4;
  --secondary-text-color: #800020;

  --style-font: "Felixti";
  --heading-font: "Orator";
  --body-font: "Roboto Mono";
}

body,
html {
  height: 100%;
  width: 100vw;
  background-color: #111;
  margin: 0;
  padding: 0;
}

body.scroll-enabled {
  overflow-y: scroll;
}

.main {
  width: 100vw;
}

body::-webkit-scrollbar {
  display: none;
}

ul {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

@media only screen and (max-width: 768px) {
  body {
    height: auto;
  }

  .main {
    overflow-y: hidden;
  }
}
