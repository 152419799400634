.contact {
  position: relative;
  z-index: 1;
  padding-left: 6.3rem;
  display: flex;
}

.contact .contact-form-container {
  background-color: #111;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact .contact-form-container .contact-header {
  color: var(--primary-text-color);
  max-width: 650px;
}

.contact .contact-form-container .contact-header h1 {
  font-family: var(--style-font);
  font-weight: lighter;
  font-size: 3em;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.contact .contact-form-container .contact-header p {
  font-family: var(--body-font);
  opacity: 0.6;
  margin-top: 0;
  font-size: 0.9em;
}

.contact .contact-form-container form div {
  display: flex;
  flex-direction: column;
  color: var(--primary-text-color);
}

.contact .contact-form-container form div label {
  font-family: var(--style-font);
  font-size: 1.5em;
}

.contact .contact-form-container form div input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--primary-text-color);
  margin-right: 1rem;
  color: var(--primary-text-color);
  font-family: var(--heading-font);
  font-size: 1.25em;
}

.contact .contact-form-container form .top-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.contact .contact-form-container form .top-row div {
  width: 50%;
}

.contact .contact-form-container form .message-container textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--primary-text-color);
  color: var(--primary-text-color);
  font-family: var(--body-font);
}

.contact .contact-form-container form .message-container textarea::placeholder {
  font-family: var(--style-font);
  color: var(--primary-text-color);
  opacity: 1;
  font-size: 1.5em;
}

.contact .contact-form-container form select {
  background-color: #111;
  border: none;
  border-bottom: 1px solid var(--primary-text-color);
  font-family: var(--heading-font);
  color: var(--primary-text-color);
  font-size: 1.25em;
  padding-left: 0;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

.contact .contact-form-container form button {
  width: 100%;
  margin-top: 2rem;
  background-color: transparent;
  border: 1px solid var(--primary-text-color);
  font-family: var(--style-font);
  font-size: 2em;
  color: var(--primary-text-color);
  padding: 1rem 0;
  cursor: pointer;
  transition: all 0.5s ease;
}

.contact .contact-form-container form button:hover {
  color: var(--secondary-text-color);
  background-color: var(--primary-text-color);
}

.contact .contact-form-container .message-status {
  color: var(--primary-text-color);
  font-family: var(--body-font);
  font-size: 1.25em;
  max-width: 600px;
  text-align: center;
  opacity: 0;
  font-weight: lighter;
  transition: all 0.5s ease;
  margin-top: -0.7rem;
}

.contact .contact-form-container .message-status.show {
  opacity: 0.6;
  margin-top: 0;
}

.contact .slider {
  width: fit-content;
  height: auto;
  border-left: 1px solid #fff;
}

.contact .slider img {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 47vw;
  max-height: 900px;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .contact {
    padding-left: 3rem;
    flex-direction: column;
  }

  .contact .slider {
    display: none;
  }

  .contact .contact-form-container {
    max-width: 250px;
    margin: 0 auto;
  }

  .contact .contact-form-container .contact-header {
    margin-top: 4rem;
  }

  .contact .contact-form-container .contact-header h1 {
    font-size: 1.7em;
  }

  .contact .contact-form-container .contact-header p {
    font-size: small;
  }

  .contact .contact-form-container form .top-row {
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
  }

  .contact .contact-form-container form .top-row div {
    width: 100%;
    margin-bottom: 1rem;
  }

  .contact .contact-form-container form div label {
    font-size: 1em;
  }

  .contact
    .contact-form-container
    form
    .message-container
    textarea::placeholder {
    font-size: 1em;
  }

  .contact .contact-form-container form button {
    margin-top: 1rem;
    font-size: 1em;
    margin-bottom: 4rem;
  }
}
