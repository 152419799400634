header {
  position: relative;
  z-index: 8;
  border-bottom: 1px solid var(--primary-text-color);
  padding-left: 6.3rem;
}

header nav ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

header nav ul li {
  color: var(--primary-text-color);
  list-style: none;
  font-family: var(--heading-font);
  font-size: 2em;
  padding: 2rem;
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease;
}

header nav .navigation-cells {
  width: 100%;
  border-left: 1px solid var(--primary-text-color);
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
}

header nav .navigation-cells:hover {
  background-color: var(--primary-text-color);
}

header nav .navigation-cells li:hover {
  color: var(--secondary-text-color);
}

header nav .navigation-cells a:hover {
  color: var(--secondary-text-color);
}

header nav .navigation-cells:first-child {
  border-left: none;
}

header nav .navigation-cells .dropdown {
  position: absolute;
  margin-top: 2.1rem;
  width: 95%;
  left: 0;
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
}

header nav .navigation-cells .dropdown li {
  font-size: 0.8em;
  padding: 0;
  max-width: 400px;
  white-space: nowrap;
}

header a {
  color: var(--primary-text-color);
}

@media only screen and (max-width: 768px) {
  header {
    display: none;
  }
}
