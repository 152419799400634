.hero {
  padding-left: 6.3rem;
}

.hero .content {
  position: relative;
  z-index: 1;
  padding-left: 7rem;
  padding-top: 7rem;
}

.hero video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  filter: blur(15px);
}

.hero .content img {
  max-width: 150px;
}

.hero .content h1 {
  color: var(--primary-text-color);
  font-family: var(--style-font);
  font-weight: lighter;
  font-size: xxx-large;
  max-width: 650px;
}

.hero .content h3 {
  color: var(--primary-text-color);
  font-family: var(--heading-font);
  font-weight: lighter;
  font-size: 1.3em;
  max-width: 650px;
  text-align: justify;
}

.hero .content h4 {
  color: var(--primary-text-color);
  font-family: var(--body-font);
  font-weight: lighter;
  font-size: 1em;
  max-width: 650px;
  text-align: justify;
  opacity: 0.7;
  margin-top: 0;
}

@media only screen and (max-width: 768px) {
  .hero {
    margin: 0 auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5rem;
  }

  .hero .content .reso-button {
    background-color: var(--primary-text-color);
    padding: 1rem 2rem;
    border-radius: 5px;
    border: none;
  }

  .hero .content .reso-button a {
    color: var(--secondary-text-color);
    font-size: 1.3em;
    font-family: var(--body-font);
  }

  .hero video {
    filter: blur(30px);
    overflow: hidden;
    width: 60%;
  }

  .hero .content {
    padding-left: 0;
    padding-top: 0rem;
  }

  .hero .content h3,
  .hero .content h4 {
    display: none;
  }

  .hero .content img {
    max-width: 120px;
    display: none;
  }

  .hero .content h1 {
    font-size: 2.25em;
    max-width: 250px;
  }

  .hero .content h3 {
    max-width: 250px;
    font-size: 1em;
  }

  .hero .content h4 {
    max-width: 250px;
    font-size: small;
  }
}
