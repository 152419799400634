.announcement-bar {
  position: relative;
  z-index: 1;
  background-color: #f9f6e4;
  margin-left: 6.3rem;
  margin-top: 10rem;
}

.announcement-bar h1 span {
  margin-right: 5rem;
  font-family: var(--heading-font);
  color: var(--secondary-text-color);
  font-size: 0.8em;
  font-weight: lighter;
}

@media only screen and (max-width: 768px) {
  .announcement-bar {
    margin-left: 3.1rem;
    margin-top: 5rem;
  }

  .announcement-bar h1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.5em;
  }
}
